import { Controller } from "@hotwired/stimulus";
import axios from 'axios'

export default class extends Controller {
  connect() {
    $('#3ds-file').on('change', e => {
      $('#submit').prop('disabled', false)
    })

    $(this.element).on('submit', e => {
      e.preventDefault()

      if (!$('#3ds-file').val()) return;

      $('#submit').text('Please Wait!')
      $('#submit').prop('disabled', true)

      var formData = new FormData(e.target);
      formData.append("file", $('#3ds-file')[0].files[0])
      $.ajax({
        url: e.target.action,
        data: formData,
        cache: false,
        type: "POST",
        dataType: 'script',
        method: "POST",
        processData: false,
        contentType: false
      })
    })
  }
}
