import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    $(this.element).on('keyup', e => {
      if (e.keyCode === 13) {
        $(e.target).parents('form').submit()
      }
    })
    $(this.element).on('autocomplete.select', (e, item) => {
      $(`input#tag_id`).val(item.value)
    })
    $(this.element).autoComplete({
      resolverSettings: {
        url: this.urlValue,
        queryKey: 'term'
      },
      minLength: 0,
      preventEnter: true,
      events: {
        typed: (newVal) => {
          if (newVal.indexOf('#') != 0) return false;
          let val = newVal.slice(1)
          return val
        },
        searchPost: (response) => {
          return response.results.map(item => ({ value: item.id, text: item.name }))
        }
      },
      formatResult: (item) => {
        return `#${item.text}`
      }
    })
  }
}
